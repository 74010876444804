<ng-template #songRightholdersInfoModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="hideModal()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        [classGroup]="'squaredHover'"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div class="modal-content">
      <div class="modal-body">
        <div>
          <div class="artist-img-container" *ngIf="dataInfo.artistImg">
            <img
              class="img-circle ml-5 mr-5"
              src="{{ dataInfo.artistImg }}"
              onerror="this.src='./assets/images/placeholder.webp';"
              alt="Image Profile"
            />
          </div>
          <div>
            <h2 class="mb-0">
              {{ dataInfo.artist?.name || dataInfo.artist?.artistName || dataInfo.artist }}
            </h2>
            <p>{{ dataInfo.locationString }}</p>
            <br />
          </div>
        </div>
        <div
          *ngIf="dataInfo.songCopyright && dataInfo.songCopyright !== ''"
          class="mt-10 mb-5 ml-5 mr-5"
        >
          <h6>Copyright:</h6>
          <p>{{ dataInfo.songCopyright }}</p>
        </div>
        <div
          *ngIf="
            dataInfo.copyrightWarning?.length > 0 &&
            (dataInfo.acrCloudPayload.coverSongs.length > 0 ||
              dataInfo.acrCloudPayload.music.length > 0)
          "
          class="pt-1 ml-5 mr-5 mb-5"
        >
          <span
            class="material-icons color-primary pull-left mr-1"
            style="position: relative; top: 5px"
            [attr.data-icon]="'warning'"
          ></span>
          <h6>Copyright Warnings:</h6>
          @for (warning of dataInfo.copyrightWarning; let i = $index; track i) {
          <h6 class="mt-5">{{ warning === 'cover' ? 'Possible Cover' : 'Major Label' }}:</h6>
          @for (song of warning === 'cover' ? dataInfo.acrCloudPayload.coverSongs :
          dataInfo.acrCloudPayload.music; track $index) {
          <div class="songs-wrapper mb-2.5">
            <p *ngIf="song?.title"><span>Song: </span>{{ song.title }}</p>
            <p *ngIf="song?.label"><span>Label: </span> {{ song.label }}</p>
            <p *ngIf="song?.artists"><span>Artists: </span> {{ song.artists }}</p>
            <p *ngIf="song?.duration && song?.beginOffset && song?.endOffset" class="time-fields">
              <span>Match: </span>
              {{
                (song.beginOffset | msToTimeFormat) +
                  ' - ' +
                  (song.endOffset | msToTimeFormat) +
                  ' (' +
                  (song.duration | msToTimeFormat) +
                  ' mins)'
              }}
            </p>
          </div>
          } }
        </div>
        <div
          *ngIf="
            dataInfo?.songRestrictions?.length > 0 ||
            (dataInfo.songRestriction && dataInfo.songRestriction !== '')
          "
          class="pt-1 mb-5 ml-5 mr-5"
        >
          <h6>Song Restrictions:</h6>
          <ul class="pl-5" *ngIf="dataInfo.songRestrictions?.length > 0">
            <li *ngFor="let restriction of dataInfo.songRestrictions">
              <p class="fw500">{{ restriction.name }}</p>
              <p *ngIf="restriction.expirationDate">
                Restricted until: {{ restriction.expirationDate | truncate : [10, ''] }}
              </p>
              <p *ngIf="restriction.details">Details: {{ restriction.details }}</p>
            </li>
          </ul>
          <p *ngIf="dataInfo.songRestriction && dataInfo.songRestriction !== ''">
            {{ dataInfo.songRestriction }}
          </p>
        </div>
        <div *ngIf="dataInfo.internalManager" class="pt-1 mb-5 ml-5 mr-5">
          <span
            class="material-icons color-primary pull-left mr-1"
            style="position: relative; top: 5px"
            [attr.data-icon]="'support_agent'"
          ></span>
          <h6>Internal manager:</h6>
          <p>{{ dataInfo.internalManager.email || dataInfo.internalManager }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
